import React, { useState, useRef } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {mailingListHolder, submitHolder, resultHolder, formHolder, nameHolder} from "./Mailchimp.module.scss"

export default function MailchimpForm({style}) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(false)
  const formRef = useRef()

  const updateFirstName = (e) => setFirstName(e.target.value)
  const updateLastName = (e) => setLastName(e.target.value)
  const updateEmail = (e) => setEmail(e.target.value)

  // useEffect(()=>{
  // }, [result])


  function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
  }


  const submit = (e) => {
    e.preventDefault()
    setLoading(true)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": e.target.getAttribute("name"),
        "email": email,
        "firstName": firstName,
        "lastName": lastName
      })
    }).then(() => {
      setLoading(false)
      setResult('success')
      console.log('SUCCESS!')
    })
    .catch(error => {
      setResult('error')
      setLoading(false)
      alert(error)
    })

    // const response = await addToMailchimp(email, {FNAME: firstName, LNAME: lastName})
    // setLoading(false)
    // setResult(response)
  }



  const resetForm = () => {
    setLoading(false)
    setResult(null)
  }

  return (
    <div style={style} className={mailingListHolder}>
        <h4>Join the mailing list</h4>
        { (result===null) ? 
          // <form className={s.form} method="POST" ref={formRef} onSubmit={(e)=>submit(e)} name="mailinListForm" data-netlify="true" netlify-honeypot="bot-field" id="mailing-list-form">
          <form  name="mailingListForm" method="POST" data-netlify="true" netlify-honeypot="bot-field" id="mailing-list-form" className={formHolder} ref={formRef} onSubmit={(e)=>submit(e)}>
            <input type="hidden" name="form-name" value="mailingListForm" />
            <input type="email" name="email" id="email" placeholder="Your email" required value={email} onChange={(e)=>updateEmail(e)} />
            <div className={nameHolder}>
              <input type="text" name="firstName" placeholder="First name" required value={firstName} onChange={(e)=>updateFirstName(e)} />
              <input type="text" name="lastName" placeholder="Last name" required value={lastName} onChange={(e)=>updateLastName(e)} />
            </div>
            <input className={submitHolder} type="submit" value="Sign up"/>
          </form>
          :
          <div className={resultHolder}>
            {
              (result==='success') ?
              <p>Thanks for subscribing!</p>
              :
              <div>
                <p>Sorry, something went wrong:</p>
                <p><button onClick={()=>{ resetForm() }}>Try again</button></p>
              </div>
            }
          </div>
          }
    </div>
  )

  // return (
  //   <form name="mailingListForm" method="POST" data-netlify="true" netlify-honeypot="bot-field" id="mailing-list-form">
  //   <input type="hidden" name="form-name" value="mailingListForm" />
  //   <p>
  //     <label for="name">Name</label>
  //     <input type="text" name="name" required />
  //   </p>
  //   <p>
  //     <label for="email">Email</label>
  //     <input type="email" name="email" required />
  //   </p>
  //   <p>
  //     <button type="submit">Add me</button>
  //   </p>
  //   </form>
  // )
}
