import { Link } from "gatsby"
import React, { useContext } from "react"

import { useTrail, a, useSpring } from "react-spring"

import MenuContext from "./MenuContext"
import SocialNav from "./SocialNav"
import Face from "../images/svgs/soul2soulLogo-no-text.svg"

const links = [
  () => (
    <Link to="/news" title="Soul2Soul News">
      News
    </Link>
  ),
  () => (
    <Link to="/events" title="Soul2Soul Events">
      Events
    </Link>
  ),
  () => (
    <Link to="/music" title="Soul2Soul Music">
      Music
    </Link>
  ),
  () => (
    <a
      href="https://bit.ly/YoutubeSoul2SoulUK"
      title="Soul2Soul on Youtube"
      target="_blank"
      rel="noreferrer"
    >
      Videos
    </a>
  ),
  () => (
    <a
      href="https://linktr.ee/BackIILife"
      title="Jazzie B on Mixcloud"
      target="_blank"
      rel="noreferrer"
    >
      Radio Show
    </a>
  ),
  () => (
    <Link to="/about" title="Soul2Soul History">
      About
    </Link>
  ),
  () => (
    <a
      href="https://www.awaywithmedia.com/buy-books/jazzieb"
      title="Jazzie B's Book"
      target="_blank"
      rel="noreferrer"
    >
      The Jazzie B Book
    </a>
  ),
  () => (
    <a
      href="https://soul2soul.tmstor.es/"
      title="Official Soul2Soul store"
      target="_blank"
      rel="noreferrer"
    >
      Store
    </a>
  ),
  () => (
    <a
      href="https://soul2soulstore.com/"
      title="Official Soul2Soul merchandise"
      target="_blank"
      rel="noreferrer"
    >
      Merch
    </a>
  ),
  // ()=> <Link to="/forsale" title="Equipment and memorabilia">For Sale</Link>
]

const Logo = ({ style }) => {
  return <Face className="logo" style={style} />
}

const AniLogo = a(Logo)

function Trail({ open, children, setNav, ...props }) {
  const items = React.Children.toArray(children)
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    from: { opacity: 0, x: 20 },
  })
  return (
    <>
      {trail.map(({ x, ...rest }, index) => (
        <a.li
          onClick={() => setNav(false)}
          key={index}
          style={{
            ...rest,
            transform: x.interpolate(x => `translateX(${x}px)`),
          }}
        >
          {items[index]}
        </a.li>
      ))}
    </>
  )
}

export const MainNavLinksTrail = () => {
  const [isOpen, setNav] = useContext(MenuContext)
  const [props, set, stop] = useSpring(() => ({ opacity: 1 }))

  // Update spring with new props
  set({ opacity: isOpen ? 1 : 0 })
  // Stop animation
  stop()

  return (
    <ul>
      <Trail open={isOpen} setNav={setNav}>
        {links.map((Link, index) => (
          <Link key={index} />
        ))}
        <SocialNav horizontal={true} />
      </Trail>
      <AniLogo style={props} />
    </ul>
  )
}

const MainNavLinks = ({ style, className }) => {
  return (
    <ul style={style} className={className}>
      {links.map((Link, index) => (
        <li key={index}>
          <Link />
        </li>
      ))}
    </ul>
  )
}

export default MainNavLinks
