/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import Layout from "./src/components/Layout"
import { MenuProvider } from "./src/components/MenuContext"

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}

export function wrapRootElement({ element }) {
  return <MenuProvider>{element}</MenuProvider>
}

export function onRouteUpdate({ location, prevLocation }) {
  // if(location.pathname==='/events') {
  //     addScript("//widget.songkick.com/365453/widget.js")
  //     addScript("//widget.songkick.com/292460/widget.js")
  // }
}
