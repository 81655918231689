import React from 'react'
import {footerHolder} from './Footer.module.scss'

export default function Footer() {
  let year = new Date()
  year = year.getFullYear()
  return (
    <footer className={footerHolder}>
      © Soul II Soul {year} | Website by <a href="mailto:ben@mrbjjackson.com" title="Web design and development by Ben Jackson - Mr BJ Jackson">Mr BJ Jackson</a>
    </footer>
  )
}
