import { Link } from "gatsby"
import React, { useContext } from "react"
import MusicVideo from "./MusicVideo"
// import YoutubeVideo from "./YoutubeVideo"

import Logo from "../images/svgs/soul2soulLogo-no-text.svg"

import Letters from "./Letters"

import { default as HomepageNav } from "./MainNavLinks"

import {
  faceHolder,
  headerHolder,
  isHomeHolder,
  videoGridHolder,
  homeNavHolder,
} from "./header.module.scss"

import MenuContext from "./MenuContext"

import { useTransition, animated } from "react-spring"

import SocialNav from "./SocialNav"

const AniMusicVideo = animated(MusicVideo)
const AniHomepageNav = animated(HomepageNav)

const Header = ({ isHome }) => {
  const [, setNav] = useContext(MenuContext)
  console.log("isHome", isHome)

  const videoTransitions = useTransition(isHome, null, {
    from: { opacity: 1, marginTop: "0" },
    enter: { opacity: 1, marginTop: "0" },
    leave: { opacity: 0, marginTop: "-50rem" },
  })

  const linkTransitions = useTransition(isHome, null, {
    from: { opacity: 0, marginTop: "-50rem" },
    enter: { opacity: 1, marginTop: "0" },
    leave: { opacity: 0, marginTop: "-50rem" },
  })

  return (
    <>
      <Link className={faceHolder} to="/">
        <Logo onClick={() => setNav(false)} />
      </Link>
      <header className={`${headerHolder} ${isHome && isHomeHolder}`}>
        <div className={videoGridHolder}>
          {videoTransitions.map(
            ({ item, key, props }) =>
              item && <AniMusicVideo key={key} style={props} />,
          )}

          {linkTransitions.map(
            ({ item, key, props }) =>
              item && (
                <AniHomepageNav
                  key={key}
                  style={props}
                  className={homeNavHolder}
                />
              ),
          )}
        </div>

        <Link to="/">
          <Letters isHome={isHome} />
        </Link>

        {isHome && <SocialNav style={{ color: "white" }} horizontal={true} />}
      </header>
    </>
  )
}

export default Header
