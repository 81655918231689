import React from "react"
import {iconsHolder, socialLinksHorizontal, socialLinksVerticle} from './SocialNav.module.scss'
import Spotify from '../images/svgs/socialIcons/sp.svg'
import Twitter from '../images/svgs/socialIcons/tw.svg'
import Facebook from '../images/svgs/socialIcons/fb.svg'
import Instagram from '../images/svgs/socialIcons/ig.svg'
import Youtube from '../images/svgs/socialIcons/yt.svg'
import Soundcloud from '../images/svgs/socialIcons/sc.svg'
import Mixcloud from '../images/svgs/socialIcons/mc.svg'
import Apple from '../images/svgs/socialIcons/ap.svg'



const SocialNav = ({horizontal, style}) => {

  return (
    <nav className={horizontal ? socialLinksHorizontal : socialLinksVerticle}>
      <ul className={iconsHolder} style={style}>
        <li><a href="http://bit.ly/SpotifySoul2SoulUK" className="sp" target="_blank" rel="noreferrer" style={style} aria-label="Soul II Soul on Spotify"><Spotify /></a></li>
        <li><a href="http://twitter.com/Soul2SoulUK" className="tw" target="_blank" rel="noreferrer" style={style} aria-label="Soul II Soul on Twitter"><Twitter /></a></li>
        <li><a href="https://linktr.ee/BackIILife" className="mc" target="_blank" rel="noreferrer" style={style} aria-label="Soul II Soul on Mixcloud"><Mixcloud /></a></li>
        <li><a href="http://www.facebook.com/soul2soulofficial" className="fb" target="_blank" rel="noreferrer" style={style} aria-label="Soul II Soul on Facebook"><Facebook /></a></li>
        <li><a href="http://www.instagram.com/soul2souluk/" className="ig" target="_blank" rel="noreferrer" style={style} aria-label="Soul II Soul on Instagram"><Instagram /></a></li>
        <li><a href="https://bit.ly/YoutubeSoul2SoulUK" className="yt" target="_blank" rel="noreferrer" style={style} aria-label="Soul II Soul on Youtube"><Youtube /></a></li>
        <li><a href="https://soundcloud.com/soul2souluk" className="sc" target="_blank" rel="noreferrer" style={style} aria-label="Soul II Soul on Soundcloud"><Soundcloud /></a></li>
        <li><a href="https://music.apple.com/us/artist/soul-ii-soul/524651" className="tw" target="_blank" rel="noreferrer" style={style} aria-label="Soul II Soul on Apple Music"><Apple /></a></li>
      </ul>
    </nav>
  )
}

export default SocialNav
