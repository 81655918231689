import React, { useState } from 'react'

import S from '../images/svgs/letters/S.svg'
import O from '../images/svgs/letters/O.svg'
import U from '../images/svgs/letters/U.svg'
import L from '../images/svgs/letters/L.svg'
import II from '../images/svgs/letters/II.svg'
import S2 from '../images/svgs/letters/S-2.svg'
import O2 from '../images/svgs/letters/O-2.svg'
import U2 from '../images/svgs/letters/U-2.svg'
import L2 from '../images/svgs/letters/L-2.svg'

import { useSpring, animated } from 'react-spring'

import {lettersHolder} from './Letters.module.scss'


const Letter = ({letter, style}) => {
  const Let = letter

  return <Let className='letter' style={style}/>
}

const AnimatedLetter = animated(Letter)


const Letters = ({isHome}) => {


  const wavey = (r, i) => `translate3d(0, ${4 * Math.sin(r + (i * 2 * Math.PI) / 1.1)}px, 0)`

  const [resetWave, setResetWave] = useState(false);

  const marginTopCalc = (factor, index) => {
    let marginTop = 0
    let gradient = 2

    marginTop = gradient * index * factor

    if(index>4)
    marginTop = (marginTop - (2 * gradient * (index - 4)) ) * factor

    return marginTop + 'em'
  }
  

  const letters = [S, O, U, L, II, S2, O2, U2, L2]


  const { radians } = useSpring({
    to:{ radians: 2 * Math.PI },
    from: { radians: 0},
    config: { duration: 3000 },
    onRest: () => setResetWave(state=> !state),
    reset: resetWave
  })


  const { home } = useSpring({ to: {home: isHome ? 1 : 0}, from: {home: isHome ? 0 : 1} }) 


  return ( 
  
      <div className={`${lettersHolder} letters-holder ${isHome ? 'homePage' : 'notHomePage'}`}>
        {
          letters.map((letter,index) => (
            <animated.div className='letter-holder' key={index} style={{ 
              transform: radians.interpolate(r => wavey(r, index)),
              marginTop: home.interpolate(home => marginTopCalc(home, index)) 
              }}>
              <AnimatedLetter className="letter" letter={letter} style={{
                  height: home.interpolate([0,1],['2em','5em']),
                  paddingLeft: home.interpolate([0,1],['0.15em','0.25em']),
                  paddingRight: home.interpolate([0,1],['0.15em','0.25em'])
                  }}/>
            </animated.div>
          ))
        }
    </div>


  )
}

export default Letters
