import React, { useContext } from "react"
import MenuContext from "./MenuContext"

import { MainNavLinksTrail } from './MainNavLinks'
import SocialNav from './SocialNav'

import {useTransition, animated, useSpring} from 'react-spring'

import {openNavHolder, closedNavHolder, shroudHolder, navHolder} from './MainNav.module.scss'
import MailchimpForm from "./MailchimpForm"


const AniMailchimpForm = animated(MailchimpForm)

const MainNav = ({isHome}) => {

const [isOpen, setNav] = useContext(MenuContext)

  
const toggleNav = () => {
  setNav(isOpen => !isOpen)
}

const shroudTransitions = useTransition(isOpen, null, {
  from: { opacity: 0 },
  enter: { opacity: 1},
  leave: { opacity: 0},
})

const props = useSpring({
  to: { 
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? 'translateX(0px)' : 'translateX(8rem)'
  }, 
  from: { 
    opacity: 0,
    transform: 'translateX(8rem)'
  },
  delay:400
})

  return (
    <>
     <button className={`hamburger hamburger--squeeze ${isOpen && 'is-active'}`} type="button" onClick={()=>toggleNav()}>
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>  
    <nav className={`${isOpen ? openNavHolder : closedNavHolder} ${navHolder}`}>
      <MainNavLinksTrail />
      <AniMailchimpForm style={props} />
    </nav>

    { shroudTransitions.map(({ item, key, props }) =>
            item && <animated.div key={key} style={props} className={shroudHolder} onClick={()=>setNav(false)} />
    )}

    { !isHome && <SocialNav /> }
    </>
  )
}

export default MainNav
