import React, { useState, useRef } from "react"

import Mute from "../images/svgs/videoControls/mute.svg"
import Pause from "../images/svgs/videoControls/pause.svg"
import Play from "../images/svgs/videoControls/play.svg"
import Controls from "../images/svgs/videoControls/settings.svg"
import Sound from "../images/svgs/videoControls/soundOn.svg"
// import Youtube from "../images/svgs/videoControls/youtube.svg"

export default function MusicVideo({ style }) {
  const ref = useRef()

  const [controls, setControls] = useState(false)
  const [muted, setMuted] = useState(true)
  const [paused, setPaused] = useState(false)
  const [videoClicked, setVideoClicked] = useState(false)

  const resyncControls = () => {
    console.log(ref)
    if (ref.current.muted || ref.current.volume === 0) setMuted(true)
    else setMuted(false)
  }

  return (
    <div className="videoHolder">
      <a
        href="https://soul-2-soul.bandcamp.com/album/soul-ii-soul-nothing-compares-to-you-remixes"
        title="Buy Nothing Compares 2 U on Bandcamp"
        target="_blank"
        rel="noreferrer"
      >
        <video
          style={style}
          ref={ref}
          autoPlay="autoplay"
          muted
          controls={controls}
          loop
          className="homeVideo"
          onPlay={() => setPaused(false)}
          onPause={() => setPaused(true)}
          // onClick={e => {
          //   setControls(true)
          //   if (!videoClicked) {
          //     e.preventDefault()
          //     ref.current.muted = false
          //     setVideoClicked(true)
          //   }
          // }}
          onVolumeChange={() => resyncControls()}
          poster="/nothing-compares-placeholder.jpg"
        >
          <source src="/nothing-compares.mp4" type="video/mp4" />
          {/* <source src="SIISfinal.webm" type="video/webm" /> */}
          Your browser does not support the video tag.
        </video>
      </a>
      <ul className="controlsHolder">
        <li>
          <button
            onClick={() => {
              ref.current.paused ? ref.current.play() : ref.current.pause()
              resyncControls()
            }}
          >
            {!paused ? <Pause /> : <Play />}
          </button>
        </li>

        <li>
          <button
            onClick={() => {
              ref.current.muted = !ref.current.muted
              ref.current.volume = 1
            }}
          >
            {muted ? <Mute /> : <Sound />}
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              setControls(controls => !controls)
            }}
          >
            <Controls />
          </button>
        </li>
        {/* <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=OFo3KTscGXk"
            title="Watch SOUL II SOUL – BACK TO LIFE (ZEPHERIN SAINT MIX) on Youtube"
          >
            <Youtube />
          </a>
        </li> */}
      </ul>
    </div>
  )
}
