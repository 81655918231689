import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import BigLogo from '../images/svgs/soul2soulLogo-no-text.svg'



const randomPercent = () => {
  return (Math.random() * 100) + '%'
}


export default function Filmgrain() {

  const [style, setStyle] = useState()

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "textures/green-film-texture-06.jpg" }) {
        childImageSharp {
          fixed(width: 3503) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const defaultStyle = {
    backgroundImage: `url(${data.placeholderImage.childImageSharp.fixed.src})`
  }


  useEffect(()=>{
    return(
      () => clearTimeout(timer)
      )
  })


  const timer = setTimeout(()=>{

    setStyle(
      { 
        ...defaultStyle,
        backgroundPosition: `${randomPercent()} ${randomPercent()}`
      }
    )
  }, 100)



  
  return (
    <div className="filmgrain" style={style}>
      <BigLogo className="bigLogo" />
    </div>
  )
}
